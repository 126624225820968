import React from "react"

import SEO from "../components/seo"

import Layout from "../components/layout"
import Marginnote from "../components/marginnote"
// import Sidenote from "../components/sidenote"

const SpeakingAndPublications = () => {
  return (
    <Layout renderName={false} allowExtraWide={false}>
      <SEO title="Other projects" keywords={[`scala`]} />
      <div className="center mw8">
        <article className="pl3 pa3 w-70-l center">
          <h1>Presentations</h1>
          <h2>"Fake news" and viral content</h2>
          <div>
            <p>
              In November 2017, I gave a short talk at the AppNexus summit with my colleague Laura
              Yu about the commonalities between fake news and low quality viral content websites,
              and how they all try to make money with ads. Sorry it&apos;s not the most detailed
              ever, we have to be mindful of the game theory involved in adversarial data science,
              but I hope you&apos;ll still find it interesting. Our most striking result (which we
              can only present in generalities) is that the patterns of traffic generation appear
              similar, regardless of the sort of scheme being used.
            </p>
            <br />
            <div className="video-container">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/9n56ljUZ7tE"
                frameBorder="0"
                allowFullScreen={true}
                className="pv3 center"
                title="yt1"
              ></iframe>
            </div>
          </div>
          <h2>Kafka and Spark Streaming</h2>
          <div>
            <p>
              In 2015 I gave a talk about one of the ways we use Spark Streaming at AppNexus to stop
              non-human traffic. Although the algorithm I describe in this talk is not exactly the
              most sophisticated, it&apos;s simple, quick, and works on all 120 billion daily events
              we see at AppNexus (or about 300k queries per second!). As of 2018 this figure has
              reached 300 billion requests per day, and the code is still running!
            </p>
            <br />
            <div className="video-container">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/CNI0DVvnyc8"
                frameBorder="0"
                allowFullScreen={true}
                className="pv3 center"
                title="yt2"
              ></iframe>
            </div>
          </div>
          <h1>Publications</h1>
          <h2>
            Identification of a novel endoplasmic reticulum stress response element regulated by
            XBP1.
          </h2>
          <p>
            <span className="small-caps">
              Misiewicz M, Déry MA, Foveau B, Jodoin J, Ruths D, LeBlanc AC. &nbsp;
            </span>
            <Marginnote
              id="pub1"
              note={
                <a href="https://doi.org/10.1074/jbc.M113.457242">
                  <i>Journal of Biological Chemistry</i>. 288. July 12 2013.{" "}
                </a>
              }
            />
            <br />
            Understanding when and where certain genes are activated in the life-cycle of a cell, in
            response to which developmental programs and what external stresses is a very important
            part of molecular biology and modern medicine. Before this research, we noticed an
            unusual sequence of DNA in the promoter of the prion gene, and we believed it could be
            important for regulating prion expression in response to stress. Our previous work
            showed that the prion gene, outside of its role in famous diseases such as mad cow
            disease, does something important to protect neurons from Endoplasimc Reticulum stress,
            which commonly occurs in cancers and other disease states. In this work, we used
            bioinformatic techniques (BLAST, Regex and Hidden Markov Models) search the entire human
            genome for this sequence. We found several dozen exact matches. Using a series of _in
            vitro_ and _in vivo_ assays, we confirmed that the genes we found were in fact regulated
            by our novel element, meaning the way in which a cell responds to stress is now better
            understood. These results may possibly explain some of the discoveries in our previous
            paper.
          </p>
          <h2>
            Loss of Anti-Bax Function in Gerstmann-Sträussler-Scheinker Syndrome-Associated Prion
            Protein Mutants.
          </h2>
          <p>
            <span className="small-caps">
              Jodoin J, Misiewicz M, Makhijani P, Giannopoulos PN, Hammond J, Goodyer CG, LeBlanc
              AC.&nbsp;{" "}
            </span>
            <Marginnote
              id="pub2"
              note={
                <a href="https://doi.org/10.1371/journal.pone.0006647">
                  <i> PLOS One</i>. August 14 2009.{" "}
                </a>
              }
            />
            <br />
            This study was a systematic analysis of mutations in the prion gene that seem to
            interfere with its normal function in protecting neurons from stressful situations.
            Previous work showed that the prion protein is important for protecting neurons and
            other cells from stressful situations. Here, we figured out which parts of the prion
            gene are apparently responsible for this protective effect, and when the protective
            effect is absent in certain prion diseases.
          </p>
          <h1>Github Projects</h1>
          <p>
            I enjoy Spark, Scala and functional programming. Check out my{" "}
            <a href="https://www.github.com/mmisiewicz">Github</a> for some small examples of my
            work in Python and Scala. Also I&apos;ve got some other projects saved there too.
          </p>
        </article>
      </div>
    </Layout>
  )
}

export default SpeakingAndPublications
