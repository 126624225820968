import React from "react"

const Marginnote = (props: {
  id: string
  note?: React.ReactChild | React.ReactFragment | React.ReactPortal
}) => (
  <>
    <label htmlFor={props.id} className="margin-toggle">
      &#8853;
    </label>
    <input type="checkbox" id={props.id} className="margin-toggle" />
    <span className="marginnote">{props.note}</span>
  </>
)

export default Marginnote
